import { template as template_01c6311833384b13aab68e23df0d6baf } from "@ember/template-compiler";
const FKLabel = template_01c6311833384b13aab68e23df0d6baf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
