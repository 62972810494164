import { template as template_9140b0b64bd6475fbdb3c50cc60ce506 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9140b0b64bd6475fbdb3c50cc60ce506(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
